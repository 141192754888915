"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _UrlEmbed = _interopRequireDefault(require("./UrlEmbed.vue"));

var _default = {
  components: {
    UrlEmbed: _UrlEmbed.default
  },
  props: ['url', 'component', 'componentProps', 'isIframe', 'showPin'],
  data: function data() {
    return {
      debouncedUpdateEmbed: null
    };
  },
  computed: {
    iframeSandboxOptions: function iframeSandboxOptions() {
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#attr-sandbox
      // Mostly all permissions other than allow-top-navigation so that embedded content
      // cannot redirect the page away from kiwi
      var options = ['allow-downloads', 'allow-forms', 'allow-modals', 'allow-orientation-lock', 'allow-pointer-lock', 'allow-popups', 'allow-popups-to-escape-sandbox', 'allow-presentation', 'allow-same-origin', 'allow-scripts'];
      return options.join(' ');
    }
  },
  watch: {
    url: function url() {
      this.debouncedUpdateEmbed();
    },
    isIframe: function isIframe() {
      this.debouncedUpdateEmbed();
    }
  },
  created: function created() {
    var _this = this;

    // Debounce as both watchers may call it in the same tick
    // also causes the method to be called next tick to give dom time to update
    this.debouncedUpdateEmbed = _lodash.default.debounce(function () {
      _this.updateEmbed();
    }, 0);
  },
  mounted: function mounted() {
    var _this2 = this;

    this.updateEmbed();
    this.$nextTick(function () {
      _this2.$state.$emit('mediaviewer.opened');
    });
  },
  methods: {
    updateEmbed: function updateEmbed() {
      this.setMaxHeight('');

      if (this.url && !this.isIframe) {
        return;
      }

      this.setHeight(this.isIframe ? '40%' : 'auto');
    },
    setHeight: function setHeight(newHeight) {
      this.$el.style.height = newHeight;
    },
    setMaxHeight: function setMaxHeight(newHeight) {
      this.$el.style.maxHeight = newHeight;
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["MediaViewer"]
window._kiwi_exports.components.MediaViewer = exports.default ? exports.default : exports;
