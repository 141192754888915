"use strict";
'kiwi public'; // What settings keys to ignore when building period delimited settings object

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.buildTree = buildTree;

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/typeof"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var ignoreKeys = ['emojis', 'themes', 'bnc', 'aliases', 'restricted', 'kiwiServer', 'hide_advanced', 'windowTitle', 'startupOptions', 'plugins', 'presetNetworks', 'ircFramework']; // Converts settings object to period delimited key based object
// eg { buffers.alert_on: false }

function buildTree(data, base, object, modified) {
  var _context;

  (0, _forEach.default)(_context = (0, _keys.default)(object)).call(_context, function (key) {
    var _context2;

    var value = object[key];
    var ourBase = (0, _concat.default)(base).call(base, [key]);

    if ((0, _indexOf.default)(_context2 = ['string', 'boolean', 'number']).call(_context2, (0, _typeof2.default)(value)) !== -1) {
      if ((0, _indexOf.default)(ignoreKeys).call(ignoreKeys, key) !== -1 || (ourBase[0] && (0, _indexOf.default)(ignoreKeys).call(ignoreKeys, ourBase[0])) !== -1) {
        return;
      }

      if (!data[ourBase.join('.')] || data[ourBase.join('.')].val !== value) {
        data[ourBase.join('.')] = {
          key: ourBase.join('.'),
          val: value,
          type: (0, _typeof2.default)(value),
          modified: modified
        };
      }
    } else if ((0, _typeof2.default)(value) === 'object' && value !== null) {
      buildTree(data, ourBase, value, modified);
    }
  });
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["settingTools"]
window._kiwi_exports.libs.settingTools = exports.default ? exports.default : exports;
