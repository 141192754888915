"use strict";

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));

var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));

var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/values"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _lodash = _interopRequireDefault(require("lodash"));

var IrcdDiffs = _interopRequireWildcard(require("@/helpers/IrcdDiffs"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = _Object$defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { _Object$defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
function inviteListSorter(a, b) {
  var aMask = a.invited.toUpperCase();
  var bMask = b.invited.toUpperCase();

  if (aMask < bMask) {
    return -1;
  }

  if (aMask > bMask) {
    return 1;
  }

  return 0;
}

var _default = {
  props: ['buffer'],
  data: function data() {
    return {
      inviteList: [],
      is_refreshing: false
    };
  },
  computed: {
    supportsAccounts: function supportsAccounts() {
      return !!this.extban;
    },
    extban: function extban() {
      return IrcdDiffs.extbanAccount(this.buffer.getNetwork());
    },
    inviteListAccounts: function inviteListAccounts() {
      var _context,
          _this = this;

      var accounts = (0, _filter.default)(_context = this.inviteList).call(_context, function (i) {
        var _context2;

        return (0, _indexOf.default)(_context2 = i.invited).call(_context2, _this.extban) === 0;
      });
      (0, _sort.default)(accounts).call(accounts, inviteListSorter);
      return accounts;
    },
    inviteListNonAccounts: function inviteListNonAccounts() {
      var _this2 = this;

      var invites = []; // If the ircd doesn't support accounts extban type, consider every invex non-account

      if (!this.supportsAccounts) {
        invites = this.inviteList;
      } else {
        var _context3;

        invites = (0, _filter.default)(_context3 = this.inviteList).call(_context3, function (i) {
          var _context4;

          return (0, _indexOf.default)(_context4 = i.invited).call(_context4, _this2.extban) !== 0;
        });
      }

      (0, _sort.default)(invites).call(invites, inviteListSorter);
      return invites;
    },
    channelIsInviteOnly: function channelIsInviteOnly() {
      return typeof this.buffer.modes.i !== 'undefined';
    },
    anyRegisteredUserCanJoin: function anyRegisteredUserCanJoin() {
      var _context5;

      if (!this.supportsAccounts) {
        return false;
      }

      if (!this.channelIsInviteOnly) {
        return false;
      }

      var extban = this.extban; // Find any invite that only consists of the extban and nothing else. Eg. '~a:'

      return !!(0, _find.default)(_context5 = this.inviteListAccounts).call(_context5, function (invite) {
        return invite.invited === extban + ':';
      });
    },
    areWeAnOp: function areWeAnOp() {
      return this.buffer.isUserAnOp(this.buffer.getNetwork().nick);
    },
    knownAccounts: function knownAccounts() {
      var _context6, _context7;

      // Get an array of every account name we're aware of on the network, excluding
      // the ones we already have in our invite list
      var users = this.buffer.getNetwork().users;
      var extban = this.extban;
      var inviteAccountNames = (0, _map.default)(_context6 = this.inviteListAccounts).call(_context6, function (i) {
        var mask = i.invited;
        return mask.replace(extban + ':', '');
      });
      var accountUsers = [];
      (0, _forEach.default)(_context7 = (0, _values.default)(users)).call(_context7, function (user) {
        if (user.account && (0, _indexOf.default)(inviteAccountNames).call(inviteAccountNames, user.account) === -1) {
          accountUsers.push(user);
        }
      });
      return _lodash.default.orderBy(accountUsers, ['account', 'nick']);
    }
  },
  created: function created() {
    this.updateInvitelist();
  },
  methods: {
    displayMask: function displayMask(invite) {
      var display = invite.invited.replace(this.extban + ':', '');
      display = display || this.$t('invite_any_registered');
      return display;
    },
    updateInvitelist: function updateInvitelist() {
      var _this3 = this;

      if (this.buffer.getNetwork().state !== 'connected' || this.is_refreshing) {
        return;
      }

      var channelName = this.buffer.name;
      this.is_refreshing = true;
      this.buffer.getNetwork().ircClient.inviteList(channelName, function (inviteEvent) {
        if (inviteEvent) {
          _this3.inviteList = inviteEvent.invites;
        } else {
          _this3.inviteList = [];
        }

        _this3.is_refreshing = false;
      });
    },
    removeInvite: function removeInvite(mask) {
      var _context8;

      var channelName = this.buffer.name;
      this.buffer.getNetwork().ircClient.removeInvite(channelName, mask);
      this.inviteList = (0, _filter.default)(_context8 = this.inviteList).call(_context8, function (invite) {
        return invite.invited !== mask;
      });
    },
    addAccountInvite: function addAccountInvite(accountName) {
      var _context9;

      if (!accountName) {
        return;
      }

      var network = this.buffer.getNetwork();
      network.ircClient.addInvite(this.buffer.name, (0, _concat.default)(_context9 = "".concat(this.extban, ":")).call(_context9, accountName));
      this.updateInvitelist();
    },
    addInvite: function addInvite(mask) {
      var network = this.buffer.getNetwork();
      network.ircClient.addInvite(this.buffer.name, mask);
      this.updateInvitelist();
    },
    setInviteOnly: function setInviteOnly() {
      this.buffer.getNetwork().ircClient.mode(this.buffer.name, '+i');
    },
    removeInviteOnly: function removeInviteOnly() {
      this.buffer.getNetwork().ircClient.mode(this.buffer.name, '-i');
    }
  }
};
exports.default = _default;