"use strict";
'kiwi public';
/** @module */

/*
 * Multiplexed channels over a websocket connection
 *     * Only allow 1 websocket per server
 *     * Multiple channels through a single websocket
 *     * A channel per IRC network connection
 * Messages are prefixed with : and the channel name then a space. eg:
 *     :1 Here is the data
 *
 * Example protocol chatter:
 *     Client > START             Client tells the server it's starting a session
 *     Server > SESSION 1234      Server sends the client the current session ID
 *     Client > :1                Client is creating a new channel, ID 1
 *     Server > :1                Server acknowledges the new channel, ID 1
 *     Client > :1 some data      Client sends data over channel 1 to the server
 *     Server > :1 some data      Server sends data over channel 1 to the client
 *     Client > :2                Client is creating a new channel, ID 2
 *     Server > :2                Server acknowledges the new channel, ID 2
 */

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.createChannelConstructor = createChannelConstructor;

var _create = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/create"));

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _sockjsClient = _interopRequireDefault(require("sockjs-client"));

var _eventemitter = _interopRequireDefault(require("eventemitter3"));

var serverConnections = (0, _create.default)(null);
var createdChannels = (0, _create.default)(null);
var controlChannel = '0';
/**
 *
 * @param {String} _addr Sockjs endpoint
 * @param {String} sessionId Optional session ID to continue
 * @param {String} _socketChannel The optional multiplexed channel ID. Autogenerated if not provided
 */

function createChannelConstructor(_addr, sessionId, _socketChannel) {
  var addr = _addr.toLowerCase();

  if (!serverConnections[addr]) {
    serverConnections[addr] = createNewConnection(addr, sessionId);
  } // If a channel ID hasn't been specified, create a new one


  var socketChannel = _socketChannel;

  if (!socketChannel) {
    socketChannel = serverConnections[addr].nextChannelId++;
  }

  return createChannelOnConnection(serverConnections[addr], socketChannel);
}
/*
 * Creates a new socket connection to a kiwi server.
 * Channels will be created on this connection to send data back and forth.
 */


function createNewConnection(wsAddr, sessionId) {
  var connection = new _eventemitter.default();
  connection.sessionId = '';
  serverConnections[wsAddr] = connection;
  connection.nextChannelId = 1;
  connection.connected = false;

  connection.reconnect = connection.connect = function connect() {
    if (connection.ws) {
      try {
        connection.ws.close();
      } catch (err) {// Ignore any closing errors. Most likely due to not
        // being connected yet.
      }

      connection.ws = null;
    }

    connection.ws = new _sockjsClient.default(wsAddr);

    connection.ws.onopen = function () {
      var connectStr = sessionId ? 'CONTROL SESSION ' + sessionId : 'CONTROL START';
      connection.ws.send(":".concat(controlChannel, " ").concat(connectStr));
      connection.connected = true;
      connection.emit('open');
    };

    connection.ws.onclose = function (err) {
      connection.connected = false;
      connection.ws = null;
      connection.emit('close', err);
    };

    connection.ws.onmessage = function (event) {
      connection.emit('message', event); // If the message starts with ":channel " then extract that channel and emit
      // an event for it.

      if (event.data[0] === ':') {
        var message = event.data;
        var spacePos = (0, _indexOf.default)(message).call(message, ' '); // If no space, ie. ":1", this is the server acknowledging this channel
        // is now open and ready to be used.

        if (spacePos === -1) {
          connection.emit('open.' + message.substr(1));
          return;
        }

        var channelId = message.substr(1, spacePos - 1);
        event.data = message.substr(spacePos + 1);
        connection.emit('message.' + channelId, event);
      } else {
        // Core messages. Used for session handling and session syncing
        var parts = event.data.split(' ');

        if (parts[0] === 'SESSION') {
          connection.sessionId = parts[1];
        }
      }
    };
  };

  connection.connect();
  return connection;
}
/*
 * Create a channel on a server connection.
 * The ConnectionChannel implements an IrcFramework transport
 */


function createChannelOnConnection(connection, channelId) {
  // Only allow 1 ConnectionChannel instance per channel
  return function ConnectionChannelWrapper(options) {
    if (!createdChannels[channelId]) {
      createdChannels[channelId] = new ConnectionChannel(options);
    } else if (connection.connected) {
      createdChannels[channelId].initChannel();
    }

    return createdChannels[channelId];
  };

  function ConnectionChannel(options) {
    var sendControlBuffer = [];
    var encoding = 'utf8';
    var channel = new _eventemitter.default();
    channel.id = channelId;
    channel.isOpen = false;
    channel.state = 0; // TODO: Is this used anywhere?
    // 0 = disconnected, 1 = connected

    channel.remoteState = 0; // When the websocket opens, open this channel on it

    connection.on('open', function () {
      connection.ws.send(':' + channelId);
    }); // When we get confirmation of this channel being opened, send any control
    // messages that were buffered

    connection.on('open.' + channelId, function () {
      channel.isOpen = true; // channel.emit('open');

      if (sendControlBuffer.length) {
        (0, _forEach.default)(sendControlBuffer).call(sendControlBuffer, function (line) {
          channel.sendControl(line);
        });
        sendControlBuffer = [];
      }

      channel.setEncoding(encoding); // This channel is now open and can start sending data to the server

      channel.remoteState = 1;
      channel.emit('open');
    });
    connection.on('close', function (err) {
      channel.state = 3;
      channel.remoteState = 0;
      channel.isOpen = false;
      channel.emit('close', err);
    });
    connection.on('message.' + channelId, function (event) {
      var _context;

      if ((0, _indexOf.default)(_context = event.data).call(_context, 'control ') === 0) {
        var _context2, _context3;

        // When we get the signal that the connection to the IRC server
        // has connected, start proxying all data
        if ((0, _indexOf.default)(_context2 = event.data).call(_context2, 'control connected') === 0) {
          channel.remoteState = 1;
        }

        if ((0, _indexOf.default)(_context3 = event.data).call(_context3, 'control closed') === 0) {
          var err = event.data.split(' ')[2];
          channel.remoteState = 0;
          channel.emit('close', err);
        }
      }

      if (channel.remoteState === 1) {
        channel.emit('line', event.data);
      }
    }); // Send a control message to the server (not relayed to an IRC network)

    channel.sendControl = function writeTarget(data) {
      if (channel.isOpen) {
        connection.ws.send(':' + channelId + ' ' + data);
      } else {
        sendControlBuffer.push(data);
      }
    };

    channel.writeLine = function writeTarget(data) {
      // Buffer the data if the socket has not yet been sent
      if (channel.remoteState >= 1) {
        connection.ws.send(':' + channelId + ' ' + data);
      }
    }; // Tell the server to connect to an IRC network


    channel.connect = function connect() {
      // Clear any buffered control messages so we have a clean slate
      sendControlBuffer = []; // If the websocket is not connected, try to reconnect it

      if (!connection.ws) {
        connection.reconnect();
      }

      var host = options.host;
      var port = options.port;
      var tls = options.tls || options.ssl;
      channel.sendControl('HOST ' + host + ':' + (tls ? '+' : '') + port);
    };

    channel.close = function close() {
      if (channel.remoteState >= 1) {
        connection.ws.send(':' + channelId);
      }
    }; // This is not supported but irc-framework transports need it, so just noop it


    channel.setEncoding = function setEncoding(newEncoding) {
      encoding = newEncoding;

      if (connection.connected) {
        connection.ws.send(':' + channelId + ' ENCODING ' + newEncoding);
      }

      return true;
    };

    channel.disposeSocket = function disposeSocket() {// noop
    };

    channel.initChannel = function initChannel() {
      connection.ws.send(':' + channelId);
    }; // Let the server know of this new channel if we're already connected


    if (connection.connected) {
      channel.initChannel();
    }

    return channel;
  }
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["ServerConnection"]
window._kiwi_exports.libs.ServerConnection = exports.default ? exports.default : exports;
