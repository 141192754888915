"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default
  },
  props: ['buffer'],
  computed: {
    network: function network() {
      return this.buffer.getNetwork();
    }
  },
  methods: {
    isActiveBuffer: function isActiveBuffer() {
      var buffer = this.buffer;
      return buffer.networkid === this.$state.ui.active_network && buffer.name === this.$state.ui.active_buffer;
    },
    awayNotifySupported: function awayNotifySupported() {
      return this.network.ircClient.network.cap.isEnabled('away-notify');
    },
    showMessageCounts: function showMessageCounts(buffer) {
      return !this.buffer.setting('hide_message_counts');
    },
    closeBuffer: function closeBuffer(buffer) {
      this.$state.removeBuffer(buffer);
    }
  }
};
exports.default = _default;