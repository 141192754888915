"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));

var embedlyTagIncluded = false;
var _default = {
  props: ['url', 'showPin', 'iframeSandboxOptions'],
  data: function data() {
    return {
      debouncedUpdateEmbed: null
    };
  },
  computed: {
    settings: function settings() {
      return this.$state.setting('embedly');
    }
  },
  watch: {
    url: function url() {
      this.updateEmbed();
    }
  },
  created: function created() {
    this.updateEmbed();
  },
  methods: {
    updateEmbed: function updateEmbed() {
      var _this = this;

      var checkEmbedlyAndShowCard = function checkEmbedlyAndShowCard() {
        // If the embedly function doesn't exist it's probably still loading
        // the embedly script
        if (typeof window.embedly !== 'function') {
          (0, _setTimeout2.default)(checkEmbedlyAndShowCard, 100);
          return;
        }

        _this.$nextTick(function () {
          window.embedly('card', _this.$refs.embedlyLink);

          _this.$emit('setHeight', 'auto');

          if (_this.showPin) {
            // This is inline so set a max height
            _this.$el.style.maxHeight = (_this.settings.maxHeight || 400) + 'px';
          } else {
            // This is main media view set a relative max height
            _this.$emit('setMaxHeight', '54%');
          }
        });
      };

      if (!embedlyTagIncluded) {
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.type = 'text/javascript';
        var embedlyUrl = this.$state.getSetting('settings.embedly.script') || '//cdn.embedly.com/widgets/platform.js';
        script.src = embedlyUrl;
        head.appendChild(script);
        embedlyTagIncluded = true;
      }

      checkEmbedlyAndShowCard();
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["UrlEmbed"]
window._kiwi_exports.components.UrlEmbed = exports.default ? exports.default : exports;
