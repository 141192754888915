"use strict";
'kiwi public';
/** @module */

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = bouncerMiddleware;

var _indexOf = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/index-of"));

var _parseInt2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/parse-int"));

var _promise = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/promise"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var _forEach = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/for-each"));

var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));

var _ircFramework = require("irc-framework");

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = _Object$defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { _Object$defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

/**
 * Adds the BOUNCER IRCv3 spec to irc-framework
 */
function bouncerMiddleware() {
  var networks = [];
  var buffers = {};
  return function middleware(client, rawEvents, parsedEvents) {
    client.requestCap('bouncer');
    addFunctionsToClient(client);
    rawEvents.use(theMiddleware);
  };

  function theMiddleware(command, message, rawLine, client, next) {
    var _context, _context2, _context3;

    if (command !== 'BOUNCER') {
      next();
      return;
    }

    var params = message.params;

    if (params[0] === 'listnetworks' && (0, _indexOf.default)(_context = ['end', 'RPL_OK']).call(_context, params[1]) > -1) {
      client.command_handler.emit('bouncer networks', networks);
      networks = [];
    } else if (params[0] === 'listnetworks') {
      var tags = _ircFramework.MessageTags.decode(params[2]);

      networks.push({
        networkId: params[1],
        name: tags.network,
        host: tags.host,
        port: (0, _parseInt2.default)(tags.port, 10),
        tls: tags.tls === '1',
        connected: tags.state === 'connected',
        nick: tags.nick,
        currentNick: tags.currentNick,
        password: tags.password || '',
        account: tags.account || '',
        account_password: tags.account_password || ''
      });
    } else if (params[0] === 'listbuffers' && (0, _indexOf.default)(_context2 = ['end', 'RPL_OK']).call(_context2, params[2]) > -1) {
      var netId = params[1] || '';
      var detectedBuffers = buffers[netId] || [];
      delete buffers[netId];
      client.command_handler.emit('bouncer buffers', detectedBuffers);
      client.command_handler.emit('bouncer buffers ' + netId, detectedBuffers);
    } else if (params[0] === 'listbuffers') {
      var _netId = params[1] || '';

      var _tags = _ircFramework.MessageTags.decode(params[2]);

      buffers[_netId] = buffers[_netId] || [];

      buffers[_netId].push({
        networkId: _netId,
        network: _tags.network,
        name: _tags.buffer,
        topic: _tags.topic,
        joined: _tags.joined === '1',
        seen: _tags.seen,
        notify: _tags.notify || 'highlight'
      });
    } else if (params[0] === 'state') {
      client.command_handler.emit('bouncer state', {
        networkId: params[1],
        network: params[2],
        state: params[3]
      });
    } // BOUNCER addnetwork NetID Network1 ERR_NAMEINUSE


    if (params[0] === 'addnetwork' && params[3].substr(0, 4) === 'ERR_') {
      var netName = (params[2] || '').toLowerCase();
      var eventObj = {
        error: params[3],
        reason: params[4] || ''
      };
      client.command_handler.emit('bouncer addnetwork error', eventObj);
      client.command_handler.emit('bouncer addnetwork error ' + netName, eventObj);
    } else if (params[0] === 'addnetwork' && (0, _indexOf.default)(_context3 = ['end', 'RPL_OK']).call(_context3, params[3]) > -1) {
      // BOUNCER addnetwork 5 Network1 RPL_OK
      var _netId2 = params[1] || '';

      var _netName = (params[2] || '').toLowerCase();

      var _eventObj = {
        networkId: _netId2,
        network: _netName
      };
      client.command_handler.emit('bouncer addnetwork ok', _eventObj);
      client.command_handler.emit('bouncer addnetwork ok ' + _netName, _eventObj);
    }
  }
}

function addFunctionsToClient(client) {
  var bnc = client.bnc = {};

  bnc.tags = function tags() {
    var token = client.network.supports('bouncer');
    return !token || typeof token !== 'string' ? {} : _ircFramework.MessageTags.decode(token) || {};
  };

  bnc.hasNetwork = function hasNetwork() {
    var token = client.network.supports('bouncer');

    if (!token || token === true) {
      return false;
    }

    var tags = _ircFramework.MessageTags.decode(token);

    return tags && !!tags.network;
  };

  bnc.getNetworks = function getNetworks() {
    return new _promise.default(function (resolve, reject) {
      client.raw('BOUNCER listnetworks');
      client.once('bouncer networks', function (networks) {
        resolve(networks);
      });
    });
  };

  bnc.getBuffers = function getBuffers(netId) {
    return new _promise.default(function (resolve, reject) {
      client.raw('BOUNCER listbuffers ' + netId);
      client.once('bouncer buffers ' + netId, function (buffers) {
        resolve(buffers);
      });
    });
  };

  bnc.closeBuffer = function closeBuffer(netId, bufferName) {
    return new _promise.default(function (resolve, reject) {
      var _context4;

      client.raw((0, _concat.default)(_context4 = "BOUNCER delbuffer ".concat(netId, " ")).call(_context4, bufferName));
    });
  };

  bnc.bufferSeen = function bufferSeen(netId, bufferName, seenTime) {
    return new _promise.default(function (resolve, reject) {
      var _context5, _context6;

      // 1 tells the bouncer to use the current time
      var timeStr = seenTime ? Misc.dateIso(seenTime) : '1';
      client.raw((0, _concat.default)(_context5 = (0, _concat.default)(_context6 = "BOUNCER changebuffer ".concat(netId, " ")).call(_context6, bufferName, " seen=")).call(_context5, timeStr));
    });
  };

  bnc.addNetwork = function addNetwork(netName, host, port, tls, nick, user, password) {
    var tags = {};
    tags.network = netName;
    tags.host = host;
    tags.port = port;
    tags.tls = tls ? 1 : 0;
    tags.nick = nick;
    tags.password = password;

    if (user) {
      tags.user = user;
    }

    var tagString = createTagString(tags);
    return new _promise.default(function (resolve, reject) {
      client.raw('BOUNCER addnetwork ' + tagString);
      client.once('bouncer addnetwork ok ' + netName.toLowerCase(), onOk);
      client.once('bouncer addnetwork error', onError);

      function onOk(event) {
        client.off('bouncer addnetwork error', onError);
        resolve(event);
      }

      function onError(event) {
        client.off('bouncer addnetwork ok ' + netName.toLowerCase(), onOk);
        reject({
          error: event.error,
          reason: event.reason
        });
      }
    });
  };

  bnc.removeNetwork = function removeNetwork(netId, bufferName) {
    return new _promise.default(function (resolve, reject) {
      client.raw("BOUNCER delnetwork ".concat(netId));
    });
  };

  bnc.saveNetwork = function saveNetwork(netId, opts) {
    var tags = {};

    if (typeof opts.network !== 'undefined') {
      tags.network = opts.network;
    }

    if (typeof opts.host !== 'undefined') {
      tags.host = opts.host;
    }

    if (typeof opts.port !== 'undefined') {
      tags.port = opts.port;
    }

    if (typeof opts.tls !== 'undefined') {
      tags.tls = opts.tls ? 1 : 0;
    }

    if (typeof opts.nick !== 'undefined') {
      tags.nick = opts.nick;
    }

    if (typeof opts.user !== 'undefined') {
      tags.user = opts.user;
    }

    if (typeof opts.password !== 'undefined') {
      tags.password = opts.password;
    }

    if (typeof opts.account_password !== 'undefined') {
      tags.account_password = opts.account_password;
    }

    if (typeof opts.account !== 'undefined') {
      tags.account = opts.account;
    }

    var tagString = createTagString(tags);
    return new _promise.default(function (resolve, reject) {
      if (tagString.length === 0) {
        resolve();
      } else {
        var _context7;

        client.raw((0, _concat.default)(_context7 = "BOUNCER changenetwork ".concat(netId, " ")).call(_context7, tagString));
      }
    });
  };
}

function createTagString(tags) {
  var _context8;

  var tagParts = [];
  (0, _forEach.default)(_context8 = (0, _keys.default)(tags)).call(_context8, function (tag) {
    var val = tags[tag];

    if (typeof val !== 'undefined') {
      val = val.toString().replace(' ', '\\s').replace(';', '\\:');
      tagParts.push(tag + '=' + val);
    } else {
      tagParts.push(tag);
    }
  });
  return tagParts.join(';');
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["BouncerMiddleware"]
window._kiwi_exports.libs.BouncerMiddleware = exports.default ? exports.default : exports;
