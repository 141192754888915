"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));

var _default = {
  data: function data() {
    return {
      show_help: false
    };
  },
  computed: {
    aliasText: {
      get: function getAliasText() {
        var _context;

        return (0, _trim.default)(_context = this.$state.setting('aliases')).call(_context);
      },
      set: function setAliasText(newVal) {
        this.$state.setting('aliases', (0, _trim.default)(newVal).call(newVal));
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SettingsAliases"]
window._kiwi_exports.components.SettingsAliases = exports.default ? exports.default : exports;
