"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));

var _TypingStatusIndicator = _interopRequireDefault(require("./TypingStatusIndicator"));

var _Avatar = _interopRequireDefault(require("./Avatar"));

var methods = {
  props: {},
  userColour: function userColour() {
    var props = this.props;

    if (props.nicklist.useColouredNicks) {
      return props.user.getColour();
    }

    return '';
  },
  shouldShowAvatars: function shouldShowAvatars() {
    var props = this.props;
    return props.nicklist.buffer.setting('nicklist_avatars');
  }
};
var _default = {
  inject: {
    components: {
      default: {
        AwayStatusIndicator: _AwayStatusIndicator.default,
        TypingStatusIndicator: _TypingStatusIndicator.default,
        Avatar: _Avatar.default
      }
    }
  },
  props: {
    network: Object,
    user: Object,
    nicklist: Object,
    m: {
      default: function m() {
        // vue uses this function to generate the prop. `this`==null Return our own function
        return function n() {
          // Give our methods some props context before its function is called.
          // This is only safe because the function on the methods object is called on
          // the same js tick
          methods.props = this;
          return methods;
        };
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["NicklistUser"]
window._kiwi_exports.components.NicklistUser = exports.default ? exports.default : exports;
