"use strict";
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.requestPermission = requestPermission;
exports.show = show;
exports.listenForNewMessages = listenForNewMessages;

var _setTimeout2 = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/set-timeout"));

var _bind = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/bind"));

var _assign = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/assign"));

var _lodash = _interopRequireDefault(require("lodash"));

var isEnabled = false;

function requestPermission(state) {
  // Do we support notifications?
  if (!('Notification' in window)) {
    isEnabled = false;
    return;
  } // Permissions already been granted?


  if (Notification.permission === 'granted') {
    state.$emit('notification.enabled');
    isEnabled = true;
    return;
  }

  if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        state.$emit('notification.enabled');
        isEnabled = true;
      } else {
        isEnabled = false;
      }
    });
  }
}

function show(title, body, opts) {
  if (!isEnabled) {
    return false;
  }

  var notify = new Notification(title, {
    body: body,
    icon: opts.icon
  });

  if (opts.ttl) {
    var _context;

    (0, _setTimeout2.default)((0, _bind.default)(_context = notify.close).call(_context, notify), opts.ttl);
  }

  return notify;
}

var throttledShow = _lodash.default.throttle(show, 2000);

function listenForNewMessages(state) {
  state.$on('notification.show', function (message, _opts) {
    if (!isEnabled) {
      return;
    }

    var opts = (0, _assign.default)({
      title: 'Kiwi IRC',
      message: message,
      icon: '',
      onclick: null,
      ttl: 10000
    }, _opts);

    if (!opts.message) {
      return;
    }

    var notification = throttledShow(opts.title, opts.message, {
      ttl: opts.ttl
    });

    if (notification && typeof opts.onclick === 'function') {
      notification.onclick = opts.onclick;
    }
  });
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["Notifications"]
window._kiwi_exports.libs.Notifications = exports.default ? exports.default : exports;
