"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _Object$defineProperty = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.default = void 0;

var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));

var _default = {
  props: ['buffer'],
  data: function data() {
    return {
      banlist: [],
      is_refreshing: false
    };
  },
  created: function created() {
    this.updateBanlist();
  },
  methods: {
    updateBanlist: function updateBanlist() {
      var _this = this;

      if (this.buffer.getNetwork().state !== 'connected' || this.is_refreshing) {
        return;
      }

      var channelName = this.buffer.name;
      this.is_refreshing = true;
      this.buffer.getNetwork().ircClient.banlist(channelName, function (banEvent) {
        _this.banlist = banEvent.bans;
        _this.is_refreshing = false;
      });
    },
    removeBan: function removeBan(mask) {
      var _context;

      var channelName = this.buffer.name;
      this.buffer.getNetwork().ircClient.unban(channelName, mask);
      this.banlist = (0, _filter.default)(_context = this.banlist).call(_context, function (ban) {
        return ban.banned !== mask;
      });
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ChannelBanlist"]
window._kiwi_exports.components.ChannelBanlist = exports.default ? exports.default : exports;
