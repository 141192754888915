"use strict";

var _Object$defineProperty2 = require("@babel/runtime-corejs3/core-js-stable/object/define-property");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

_Object$defineProperty2(exports, "__esModule", {
  value: true
});

exports.def = def;

var _defineProperty = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/define-property"));

// Define a non-enumerable property on an object with an optional setter callback
function def(target, key, value, canSet) {
  var val = value;
  var definition = {
    get: function get() {
      return val;
    }
  };

  if (canSet) {
    definition.set = function set(newVal) {
      var oldVal = val;
      val = newVal;

      if (typeof canSet === 'function') {
        canSet(newVal, oldVal);
      }
    };
  }

  (0, _defineProperty.default)(target, key, definition);

  if (typeof canSet === 'function') {
    canSet(val);
  }
}