var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-statebrowser-network",class:[
    _vm.isActiveNetwork ? 'kiwi-statebrowser-network--active' : '' ]},[_c('div',{staticClass:"kiwi-statebrowser-network-header"},[_c('a',{staticClass:"kiwi-statebrowser-network-name u-link",on:{"click":function($event){return _vm.setActiveBuffer(_vm.serverBuffer)}}},[_vm._v(" "+_vm._s(_vm.network.name)+" ")]),_c('div',{staticClass:"kiwi-statebrowser-buffer-actions"},[_c('div',{staticClass:"kiwi-statebrowser-channel-labels"},[(_vm.serverUnread && _vm.showMessageCounts(_vm.serverBuffer))?_c('div',{staticClass:"kiwi-statebrowser-channel-label",class:[
                        _vm.serverHighlight ?
                            'kiwi-statebrowser-channel-label--highlight' :
                            ''
                    ]},[_vm._v(" "+_vm._s(_vm.serverUnread > 999 ? "999+": _vm.serverUnread)+" ")]):_vm._e()])]),_c('div',{staticClass:"kiwi-network-name-options"},[(_vm.totalNetworkCount > 1)?_c('div',{staticClass:"kiwi-network-name-option kiwi-network-name-option-collapse",on:{"click":function($event){_vm.collapsed=!_vm.collapsed}}},[_c('i',{staticClass:"fa",class:[_vm.collapsed?'fa-plus-square-o':'fa-minus-square-o']})]):_vm._e()])]),(_vm.channel_filter_display)?_c('div',{staticClass:"kiwi-statebrowser-channelfilter"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.channel_filter),expression:"channel_filter"},{name:"focus",rawName:"v-focus"}],attrs:{"placeholder":_vm.$t('filter_channels'),"type":"text"},domProps:{"value":(_vm.channel_filter)},on:{"blur":_vm.onChannelFilterInputBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeFilterChannel($event)},"input":function($event){if($event.target.composing){ return; }_vm.channel_filter=$event.target.value}}}),_c('p',[_c('a',{on:{"click":function($event){_vm.closeFilterChannel(); _vm.showNetworkChannels(_vm.network)}}},[_vm._v(" "+_vm._s(_vm.$t('find_more_channels'))+" ")])])]):_vm._e(),(_vm.channel_add_display)?_c('div',{staticClass:"kiwi-statebrowser-channels-info"},[_c('form',{staticClass:"kiwi-statebrowser-newchannel",on:{"submit":function($event){$event.preventDefault();return _vm.submitNewChannelForm($event)}}},[_c('div',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"kiwi-statebrowser-newchannel-inputwrap",class:[
                    _vm.channel_add_input_has_focus ?
                        'kiwi-statebrowser-newchannel-inputwrap--focus' :
                        ''
                ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.channel_add_input),expression:"channel_add_input"}],attrs:{"placeholder":_vm.$t('state_join'),"type":"text"},domProps:{"value":(_vm.channel_add_input)},on:{"focus":_vm.onNewChannelInputFocus,"blur":_vm.onNewChannelInputBlur,"input":function($event){if($event.target.composing){ return; }_vm.channel_add_input=$event.target.value}}})])])]):_vm._e(),_c('div',{staticClass:"kiwi-statebrowser-network-toggable-area",class:[
        _vm.collapsed ? 'kiwi-statebrowser-network-toggable-area--collapsed' : '' ]},[_c('transition',{attrs:{"name":"kiwi-statebrowser-network-status-transition"}},[(_vm.network.state !== 'connected')?_c('div',{staticClass:"kiwi-statebrowser-network-status"},[(_vm.network.state_error)?[_c('i',{staticClass:"fa fa-exclamation-triangle",attrs:{"aria-hidden":"true"}}),_c('a',{staticClass:"u-link",on:{"click":function($event){return _vm.showNetworkSettings(_vm.network)}}},[_vm._v(" "+_vm._s(_vm.$t('state_configure'))+" ")])]:(!_vm.network.connection.server)?[_c('a',{staticClass:"u-link",on:{"click":function($event){return _vm.showNetworkSettings(_vm.network)}}},[_vm._v(" "+_vm._s(_vm.$t('state_configure'))+" ")])]:(_vm.network.state === 'disconnected')?[_vm._v(" "+_vm._s(_vm.$t('state_disconnected'))+" "),_c('a',{staticClass:"u-link",on:{"click":function($event){return _vm.network.ircClient.connect()}}},[_vm._v(" "+_vm._s(_vm.$t('connect'))+" ")])]:(_vm.network.state === 'connecting')?[_vm._v(" "+_vm._s(_vm.$t('connecting'))+" ")]:_vm._e()],2):_vm._e()]),_vm._l((_vm.filteredBuffersByType),function(itemBuffers,type){return _c('div',{key:type,staticClass:"kiwi-statebrowser-buffers",attrs:{"data-name":type}},[(!_vm.channel_filter_display && _vm.showBufferGroups && type !== 'other')?_c('div',{staticClass:"kiwi-statebrowser-channels-header"},[((type === 'queries' && itemBuffers.length) || type !== 'queries')?_c('div',{staticClass:"kiwi-statebrowser-buffertype",on:{"click":function($event){return _vm.toggleSection(type)}}},[_c('i',{staticClass:"fa kiwi-statebrowser-channels-toggle",class:[
                            (_vm.show_channels && type === 'channels') ||
                                (_vm.show_queries && type === 'queries') ?
                                    'fa-caret-down' :
                                    'fa-caret-right'
                        ]}),_vm._v(" "+_vm._s(type === 'channels' ? _vm.$t('channels') : _vm.$t('messages'))+" ")]):_vm._e(),(type === 'channels')?_c('div',{staticClass:"kiwi-statebrowser-channels-options"},[_c('div',{staticClass:"kiwi-statebrowser-channels-option",class:{ active: _vm.channel_add_display == true },on:{"click":function($event){return _vm.toggleAddChannel()}}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"kiwi-statebrowser-channels-option",class:{ active: _vm.channel_filter_display == true },on:{"click":_vm.onSearchChannelClick}},[(type === 'channels')?_c('i',{staticClass:"fa fa-search",attrs:{"aria-hidden":"true"}}):_vm._e()])]):_vm._e(),_c('div',{staticClass:"kiwi-statebrowser-buffer-actions"},[_c('div',{staticClass:"kiwi-statebrowser-channel-labels"},[(!_vm.show_channels &&
                                type === 'channels' &&
                                _vm.channelActivity.unread !== 0
                            )?_c('div',{staticClass:"kiwi-statebrowser-channel-label",class:[
                                _vm.channelActivity.highlights ?
                                    'kiwi-statebrowser-channel-label--highlight' :
                                    ''
                            ]},[_vm._v(" "+_vm._s(_vm.channelActivity.unread > 999 ? '999+' : _vm.channelActivity.unread)+" ")]):((!_vm.show_queries === true &&
                                type === 'queries' && _vm.queryActivity.unread !== 0))?_c('div',{staticClass:"kiwi-statebrowser-channel-label",class:[
                                _vm.queryActivity.highlights ?
                                    'kiwi-statebrowser-channel-label--highlight' :
                                    ''
                            ]},[_vm._v(" "+_vm._s(_vm.queryActivity.unread > 999 ? '999+' : _vm.queryActivity.unread)+" ")]):_vm._e()])])]):_vm._e(),((_vm.show_channels && type === 'channels') ||
                (_vm.show_queries && type === 'queries') ||
                type === 'other')?_c('div',_vm._l((itemBuffers),function(buffer){return _c('buffer',{key:buffer.name,attrs:{"buffer":buffer},on:{"selected":function($event){return _vm.setActiveBuffer(buffer)}}})}),1):_vm._e()])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }